import React from 'react';

export interface EquipmentMeasurements {
  length?: number;
  width?: number;
  height?: number;
}

export const getEquipmentMeasurements = (measurable: EquipmentMeasurements) => {
  return [
    measurable.length,
    measurable.width,
    measurable.height
  ].filter(measurement => Boolean(measurement)).join('x');
};

interface Props {
  item: EquipmentMeasurements;
}

export const DetailsEquipmentMeasurements: React.FunctionComponent<Props> = ({item}) =>
  <span>{getEquipmentMeasurements(item)}</span>;
