import React from 'react';
import moment from 'moment';
import { Moment } from 'moment';

import { Translation } from 'shared/components/translation/translation.component';
import { useStoredUser } from 'app/hooks/store/use-stored-user.hook';

interface WarehouseHoursProps {
  start: Date | string;
  end: Date | string;
}

const TIME_FORMAT_12 = 'hh:mm A';
const TIME_FORMAT_24 = 'HH:mm';

const getHours = (start: Moment, end: Moment, is12Hour = true) => {
  const TIME_FORMAT = is12Hour ? TIME_FORMAT_12 : TIME_FORMAT_24;
  const startTime = start.format(TIME_FORMAT);
  const endTime = end.format(TIME_FORMAT);

  if (startTime === endTime
    || (start.format(TIME_FORMAT_24) === '00:01' && end.format(TIME_FORMAT_24) === '23:59')
    || (start.format(TIME_FORMAT_24) === '24:01' && end.format(TIME_FORMAT_24) === '00:01')
  ) {
    return <span className="no-wrap"><Translation resource="TWENTY_FOUR_HOURS"/></span>;
  }
  return (
    <React.Fragment>
      <span className="no-wrap">{startTime}</span> - <span className="no-wrap">{endTime}</span>
    </React.Fragment>
  );
};

export const WarehouseHours = (props: WarehouseHoursProps) => {
  const user = useStoredUser();
  const start = moment(props.start);
  const end = moment(props.end);
  const hours = getHours(start, end, user.is12Hour());

  return (<span data-testid="WarehouseHours" >{hours}</span>);
};
