import React from 'react';
import { FunctionComponent } from 'react';
import chunk from 'lodash.chunk';

import { Modal } from 'shared/components/modal/modal.component';
import { useModal } from 'app/hooks/use-modal.hook';
import { Translation } from 'shared/components/translation/translation.component';
import { Button } from 'shared/components/elements/button.component';

export const Commodities_Modal = 'commodities';

interface Props {
  commodities: any;
}

export const CommoditiesModal: FunctionComponent<Props> = ({ commodities }) => {
  const [, hide] = useModal(Commodities_Modal);

  return (
    <Modal
      name={Commodities_Modal}
      onHide={hide}
      header={() =>
        <Translation resource="COMMODITY_DETAILS"/>
      }
      children={
        <div className="commodity-details">
          {chunk(commodities, 1).map((pair, i) => (
            <div className="single-commodity" key={i}>
              {pair.map((item, j) =>
                <div key={j}>
                  <label><Translation resource="PRODUCT" /></label>
                  {item.commodityName !== null
                    ? <p className="h3">{item.commodityName}</p>
                    : <p className="h3"><Translation resource="NO_COMMODITY_INFO_AVAILABLE"/></p>
                  }
                </div>)}
            </div>
          ))}
        </div>
      }
      footer={
        <Button btnPrimary mobileResponsive className="text-right space-outer-right-lg js-settings-close-button" onClick={hide} resource="CLOSE"/>
      }
    />
  );
};
