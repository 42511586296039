import React from 'react';

import { AppointmentType } from 'shared/enums/appointment-type.enum';
import { Translation } from 'shared/components/translation/translation.component';

interface StopAppointmentProps {
  appointmentType: number;
}

export const StopAppointment = (props: StopAppointmentProps) => {
  return (
    <div className="stop-appointment">
      {props.appointmentType === AppointmentType.APPOINTMENT_NEEDED &&
        <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path className="cls-1" d="M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,10,18Z"/>
          <path className="cls-1" d="M10.5,5H9v6l5.25,3.15L15,12.92l-4.5-2.67Z"/>
        </svg>
      }
      {props.appointmentType === AppointmentType.APPOINTMENT_SET &&
        <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.6 13.4">
          <path className="cls-1" d="M5.6,10.6,1.4,6.4,0,7.8l5.6,5.6,12-12L16.2,0Z"/>
        </svg>
      }
      {props.appointmentType === AppointmentType.FIRST_COME_FIRST_SERVE &&
        <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
          <path className="cls-1" d="M2,4H0V20a2,2,0,0,0,2,2H18V20H2ZM13,14h2V4H11V6h2ZM20,0H6A2,2,0,0,0,4,2V16a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V2A2,2,0,0,0,20,0Zm0,16H6V2H20Z"/>
        </svg>
      }
      <Translation resource={AppointmentType[props.appointmentType] as ResourceKey}/>
    </div>
  );
};
